import React, { useState } from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

import ConsultationCtaFormA from "../../../components/_V2/ConsultationCtaFormA"
import CardsBonus from "../../../assets/_V2/svg/CardsBonus"
import { useLocalizationContext } from "../../../localization/useLocalizationContext"

interface Props {
	className?: string
}

export default function LoyaltyTariffs(props: Props) {
	const localizationContext = useLocalizationContext()
	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleModalStatus = () => {
		setIsModalOpen(!isModalOpen)
	}

	return (
		<section className={classNames(styles.loyaltyTariffs__container, props.className)}>
			<article className={styles.loyaltyTariffs__item}>
				<div className={styles.loyaltyTariffs__top}>
					<h3 className={styles.loyaltyTariffs__title}>Лояльность.360</h3>
					<p className={styles.loyaltyTariffs__description}>
						Всё в&nbsp;одной подписке: мобильное приложение, сайт, telegram-бот
					</p>
					<ul className={styles.loyaltyTariffs__features__list}>
						<div className={styles.loyaltyTariffs__features__section}>
							<li className={styles.loyaltyTariffs__features__item}>База гостей, заказы, фудкост и&nbsp;аналитика
								в&nbsp;одной системе
							</li>
							<li className={styles.loyaltyTariffs__features__item}>RFM-анализ и&nbsp;push-уведомления по&nbsp;сегментам
								аудитории
							</li>
						</div>
						<div className={styles.loyaltyTariffs__features__section}>
							<li className={styles.loyaltyTariffs__features__item}>3&nbsp;формата работы: доставка, самовывоз
								и&nbsp;электронное меню
							</li>
							<li className={styles.loyaltyTariffs__features__item}>Онлайн-эквайринг с&nbsp;комиссией от&nbsp;2,6%</li>
						</div>
					</ul>
				</div>
				<div className={styles.loyaltyTariffs__bottom}>
					<div className={styles.loyaltyTariffs__footer}>
						<button
							type={"button"}
							className={styles.loyaltyTariffs__button}
							onClick={handleModalStatus}
						>

							Подключить
						</button>
						<p className={styles.loyaltyTariffs__price}>
							4990
							<span className={styles.loyaltyTariffs__currency}>₽/мес</span>
						</p>
						<CardsBonus className={styles.cardsBonus} />
					</div>
					<p className={styles.loyaltyTariffs__paymentInfo}>Разовый платеж за подключение — 8990 ₽</p>
				</div>
			</article>
			<article className={styles.loyaltyTariffs__item}>
				<div className={styles.loyaltyTariffs__top}>
					<h3 className={styles.loyaltyTariffs__title}>Лояльность.Карты</h3>
					<p className={styles.loyaltyTariffs__description}>
						Электронные карты лояльности с push-уведомлениями для iOS и Android
					</p>
					<ul className={styles.loyaltyTariffs__features__list}>
						<div className={styles.loyaltyTariffs__features__section}>
							<li className={styles.loyaltyTariffs__features__item}>Мгновенный выпуск карт</li>
							<li className={styles.loyaltyTariffs__features__item}>Активное вовлечение аудитории</li>
						</div>
						<div className={styles.loyaltyTariffs__features__section}>
							<li className={styles.loyaltyTariffs__features__item}>Сегментация базы гостей</li>
							<li className={styles.loyaltyTariffs__features__item}>Кастомизация стиля</li>
						</div>
					</ul>
				</div>
				<div className={styles.loyaltyTariffs__bottom}>
					<div className={styles.loyaltyTariffs__footer}>
						<button
							type={"button"}
							className={styles.loyaltyTariffs__button}
							onClick={handleModalStatus}
						>
							Подключить
						</button>
						<p className={styles.loyaltyTariffs__price}>
							1990
							<span className={styles.loyaltyTariffs__currency}>₽/мес</span>
						</p>
					</div>
					<p className={styles.loyaltyTariffs__paymentInfo}>Разовый платеж за подключение — 3990 ₽</p>
				</div>
			</article>
			<ConsultationCtaFormA isOpen={isModalOpen} onClose={handleModalStatus} />
		</section>
	)
}
