import React from "react"
import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
    if (locale === 'ru-BY') {
        return [
            {
                title: "Техническая поддержка 24/7",
                items: [
                    {
                        name: "Мессенджеры, чат, почта",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Телефон",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Приоритетная выделенная линия",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                ],
            },
            {
                title: "Кассовый терминал",
                items: [
                    {
                        name: "Печать тикетов на кухню",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Модификаторы",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Кухонный экран и управление очередью заказов",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "ЕГАИС (интеграция DocsInBox)",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Работа с маркированными товарами (Честный знак)",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Поддержка банковских терминалов (Оплата картой)",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Электронная очередь",
                        web: false,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Экран покупателя для кассового терминала",
                        web: false,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Выпуск готовых блюд (складские документы на терминале)",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "Складской учет и фудкост, финансы и персонал",
                items: [
                    {
                        name: "Настраиваемые виджеты оперативного учета",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Базовый учет - склад и техкарты",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Период проведения документов \"задним числом\"",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Период хранения документов",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Уведомления об опасных операциях и действиях на кассе, лимитах остатков",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Настройка полей и прав доступа",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Базовый финансовый учет",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                    {
                        name: "Продвинутый учет и аналитика, конструктор отчетов, ABC анализ",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                ],
            },
            {
                title: "Доставка",
                items: [
                    {
                        name: "Работа с заказами на самовывоз",
                        web: false,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Работа с заказами на доставку на кассе",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Своя доставка через сервис Яндекс.Доставка",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Яндекс.Еда",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "CRM",
                items: [
                    {
                        name: "Идентификация гостей по номеру телефона, QR коду или через поиск",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "RFM-анализ",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                ],
            },
            {
                title: "Управление сетью ресторанов",
                items: [
                    {
                        name: "Консолидированная отчетность и базовые элементы управления сетью в облаке (Единая отчетность для разных заведений в рамках одного облака)",
                        web: true,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Централизованный учет и продвинутые инструменты управления сетью (Сеть из нескольких заведений в рамках одного облака)",
                        web: true,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Глобальные программы лояльности (Одна программа лояльности на все заведения в рамках одного облака)",
                        web: true,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "Управление франшизой",
                items: [
                    {
                        name: "Консолидированная отчетность и базовые элементы управления сетью в облаке ",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                    {
                        name: "Централизованный учет и продвинутые инструменты управления сетью",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                    {
                        name: "Глобальные программы лояльности",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                ],
            },
            {
                title: "Дополнительные приложения",
                items: [
                    {
                        name: "Сайт для организации доставки на платформе Quick Resto",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Мобильное приложение для клиентов доставки White Label",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "Интеграции и API",
                items: [
                    {
                        name: "1С",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "Подключаемые модули",
                items: [
                    {
                        name: "Дополнительный терминал",
                        web: {
                            type: "counter",
                            price: {
                                month: 40,
                                year: 400,
                                yearOld: 400,
                            },
                            max: 9,
                        },
                        start: false,
                        standard: {
                            type: "counter",
                            price: {
                                month: 56,
                                year: 600,
                                yearOld: 600,
                            },
                            max: 9,
                        },
                        pro: {
                            type: "counter",
                            price: {
                                month: 85,
                                year: 900,
                                yearOld: 900,
                            },
                            max: 49,
                        },
                    },
                    {
                        name: "Лояльность.Карты\n(электронные карты лояльности)",
                        web: {
                            type: "value",
                            price: {
                                month: "500 BYN / год",
                                year: "500 BYN / год"
                            }
                        },
                        start: {
                            type: "value",
                            price: {
                                month: "500 BYN / год",
                                year: "500 BYN / год"
                            }
                        },
                        standard: {
                            type: "value",
                            price: {
                                month: "500 BYN / год",
                                year: "500 BYN / год"
                            }
                        },
                        pro: {
                            type: "value",
                            price: {
                                month: "500 BYN / год",
                                year: "500 BYN / год"
                            }
                        },
                    },
                    {
                        name: "Электронное меню",
                        web: {
                            type: "value",
                            price: {
                                month: "600 BYN / год",
                                year: "600 BYN / год"
                            }
                        },
                        start: {
                            type: "value",
                            price: {
                                month: "600 BYN / год",
                                year: "600 BYN / год"
                            }
                        },
                        standard: {
                            type: "value",
                            price: {
                                month: "600 BYN / год",
                                year: "600 BYN / год"
                            }
                        },
                        pro: {
                            type: "value",
                            price: {
                                month: "600 BYN / год",
                                year: "600 BYN / год"
                            }
                        },
                    },
                    {
                        name: (
                            <span>
						Приложение для гостей WLApp
						<br/>
						(push-уведомления)
					</span>
                        ),
                        web: false,
                        start: false,
                        standard: {
                            type: "value",
                            price: {
                                month: "1490 BYN / год",
                                year: "1490 BYN / год"
                            }
                        },
                        pro: {
                            type: "value",
                            price: {
                                month: "1490 BYN / год",
                                year: "1490 BYN / год"
                            }
                        },
                    },
                    {
                        name: (
                            <span>
						Приложение для гостей WLApp
						<br/>
						(push-уведомления + предзаказ)
					</span>
                        ),
                        web: false,
                        start: false,
                        standard: {
                            type: "value",
                            price: {
                                month: "1680 BYN / год",
                                year: "1680 BYN / год"
                            }
                        },
                        pro: {
                            type: "value",
                            price: {
                                month: "1680 BYN / год",
                                year: "1680 BYN / год"
                            }
                        },
                    },
                    {
                        name: "Сайт для доставки с корзиной WLSite",
                        web: false,
                        start: false,
                        standard: {
                            type: "value",
                            price: {
                                month: "1890 BYN / год",
                                year: "1890 BYN / год"
                            }
                        },
                        pro: {
                            type: "value",
                            price: {
                                month: "1890 BYN / год",
                                year: "1890 BYN / год"
                            }
                        },
                    },
                ],
            }
        ]
    }

    if (locale === 'kz-KZ') {
        return [
            {
                title: "Техникалық қолдау 24/7",
                items: [
                    {
                        name: "Мессенджерлер, чат, пошта",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Телефон",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Басым бөлінген желі",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                ],
            },
            {
                title: "Кассалық терминал",
                items: [
                    {
                        name: "Фискалдау",
                        web: false,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Асүйге тикеттерді басып шығару",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Модификаторлар",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Асүй экраны және тапсырыстар кезегін басқару",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Банк терминалдарын қолдау (Картамен төлеу)",
                        web: false,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Электрондық кезек",
                        web: false,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Кассалық терминалға арналған сатып алушының экраны",
                        web: false,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Дайын тағамдарды шығару (терминалдағы қойма құжаттары)",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "Қоймалық есепке алу және фудкост, қаржы және персонал",
                items: [
                    {
                        name: "Жедел есепке алудың теңшелетін виджеттері",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Негізгі есепке алу - қойма және техкарталар",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Құжаттарды бұрынғы күнмен жүргізу кезеңі",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Құжаттарды сақтау кезеңі",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Кассадағы қауіпті операциялар мен әрекеттер, қалдықтар лимиттері туралы хабарламалар",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Өрістерді және кіру құқықтарын теңшеу",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Негізгі қаржылық есепке алу",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                    {
                        name: "Ілгерінді есепке алу және аналитика, есептер конструкторы, АВС талдау",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                ],
            },
            {
                title: "Жеткізу",
                items: [
                    {
                        name: "Өзімен әкетуге тапсырыстармен жұмыс істеу",
                        web: false,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Кассада жеткізуге тапсырыстармен жұмыс істеу",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Яндекс.Еда",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "CRM",
                items: [
                    {
                        name: "Қонақтарды телефон нөмірі, QR коды немесе іздеу арқылы сәйкестендіру",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "RFM-талдау",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                ],
            },
            {
                title: "Мейрамханалар желісін басқару",
                items: [
                    {
                        name: "Шоғырландырылған есептілік және бұлтта желіні басқарудың негізгі элементтері (Бір бұлт шеңберінде түрлі мекемелер үшін бірыңғай есептілік)",
                        web: true,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Орталықтандырылған есепке алу және желіні басқарудың ілгерінді құралдары (Бір бұлт шеңберінде бірнеше мекемелерден тұратын желі)",
                        web: true,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Жаһандық адалдық бағдарламалары (Бір бұлт шеңберінде барлық мекемелерге бір адалдық бағдарламасы)",
                        web: true,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "Франшизаны басқару",
                items: [
                    {
                        name: "Шоғырландырылған есептілік және бұлтта желіні басқарудың негізгі элементтері",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                    {
                        name: "Орталықтандырылған есепке алу және желіні басқарудың ілгерінді құралдары",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                    {
                        name: "Жаһандық адалдық бағдарламалары",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                ],
            },
            {
                title: "Қосымша қосымшалар",
                items: [
                    {
                        name: "Menuquickresto.kz платформасында дайын жеткізу сайты",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "White Label жеткізу клиенттеріне арналған мобильді қосымша",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "Интеграциялар және АРІ",
                items: [
                    {
                        name: "1С",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "DocsInBox",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Postilla",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "Қосылатын модульдер",
                items: [
                    {
                        name: "Қосымша терминал",
                        web: {
                            type: "counter",
                            price: {
                                month: 3000,
                                year: 30000,
                            },
                            max: 99,
                        },
                        start: {
                            type: "counter",
                            price: {
                                month: 8550,
                                year: 85500,
                            },
                            max: 1,
                        },
                        standard: {
                            type: "counter",
                            price: {
                                month: 11350,
                                year: 113500,
                            },
                            max: 9,
                        },
                        pro: {
                            type: "counter",
                            price: {
                                month: 14200,
                                year: 142000,
                            },
                            max: 49,
                        },
                    },
                    {
                        name: "PassKit адалдық карталары",
                        web: {
                            type: "value",
                            price: {
                                month: "60 000 ₸ / год",
                                year: "60 000 ₸ / год",
                            }
                        },
                        start: {
                            type: "value",
                            price: {
                                month: "60 000 ₸ / год",
                                year: "60 000 ₸ / год"
                            }
                        },
                        standard: {
                            type: "value",
                            price: {
                                month: "60 000 ₸ / год",
                                year: "60 000 ₸ / год"
                            }
                        },
                        pro: {
                            type: "value",
                            price: {
                                month: "60 000 ₸ / год",
                                year: "60 000 ₸ / год"
                            }
                        },
                    },
                    {
                        name: "Электрондық мәзірлер",
                        web: {
                            type: "value",
                            price: {
                                month: "71 300 ₸ / год",
                                year: "71 300 ₸ / год"
                            }
                        },
                        start: {
                            type: "value",
                            price: {
                                month: "71 300 ₸ / год",
                                year: "71 300 ₸ / год"
                            }
                        },
                        standard: {
                            type: "value",
                            price: {
                                month: "71 300 ₸ / год",
                                year: "71 300 ₸ / год"
                            }
                        },
                        pro: {
                            type: "value",
                            price: {
                                month: "71 300 ₸ / год",
                                year: "71 300 ₸ / год"
                            }
                        },
                    },
                    {
                        name: (
                            <span>
						WLApp қонақтарға арналған қосымшасы
						<br/>
						(push-хабарламалар)
					</span>
                        ),
                        web: false,
                        start: false,
                        standard: {
                            type: "value",
                            price: {
                                month: "150 800 ₸ / год",
                                year: "150 800 ₸ / год"
                            }
                        },
                        pro: {
                            type: "value",
                            price: {
                                month: "150 800 ₸ / год",
                                year: "150 800 ₸ / год"
                            }
                        },
                    },
                    {
                        name: (
                            <span>
						WLApp қонақтарға арналған қосымшасы
						<br/>
						(push-хабарламалар + алдын ала тапсырыс беру)
					</span>
                        ),
                        web: false,
                        start: false,
                        standard: {
                            type: "value",
                            price: {
                                month: "270 500 ₸ / год",
                                year: "270 500 ₸ / год"
                            }
                        },
                        pro: {
                            type: "value",
                            price: {
                                month: "270 500 ₸ / год",
                                year: "270 500 ₸ / год"
                            }
                        },
                    },
                    {
                        name: "WLSite себеті бар жеткізуге арналған сайты",
                        web: false,
                        start: false,
                        standard: {
                            type: "value",
                            price: {
                                month: "270 500 ₸ / год",
                                year: "270 500 ₸ / год"
                            }
                        },
                        pro: {
                            type: "value",
                            price: {
                                month: "270 500 ₸ / год",
                                year: "270 500 ₸ / год"
                            }
                        },
                    },
                ],
            }
        ]
    }

    if (locale === 'ru-KZ') {
        return [
            {
                title: "Техническая поддержка 24/7",
                items: [
                    {
                        name: "Мессенджеры, чат, почта",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Телефон",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Приоритетная выделенная линия",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                ],
            },
            {
                title: "Кассовый терминал",
                items: [
                    {
                        name: "Фискализация",
                        web: false,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Печать тикетов на кухню",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Модификаторы",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Кухонный экран и управление очередью заказов",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Поддержка банковских терминалов (Оплата картой)",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Электронная очередь",
                        web: false,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Экран покупателя для кассового терминала",
                        web: false,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Выпуск готовых блюд (складские документы на терминале)",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "Складской учет и фудкост, финансы и персонал",
                items: [
                    {
                        name: "Настраиваемые виджеты оперативного учета",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Базовый учет - склад и техкарты",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Период проведения документов \"задним числом\"",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Период хранения документов",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Уведомления об опасных операциях и действиях на кассе, лимитах остатков",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Настройка полей и прав доступа",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Базовый финансовый учет",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                    {
                        name: "Продвинутый учет и аналитика, конструктор отчетов, ABC анализ",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                ],
            },
            {
                title: "Доставка",
                items: [
                    {
                        name: "Работа с заказами на самовывоз",
                        web: false,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Работа с заказами на доставку на кассе",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Яндекс.Еда",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "CRM",
                items: [
                    {
                        name: "Идентификация гостей по номеру телефона, QR коду или через поиск",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "RFM-анализ",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                ],
            },
            {
                title: "Управление сетью ресторанов",
                items: [
                    {
                        name: "Консолидированная отчетность и базовые элементы управления сетью в облаке (Единая отчетность для разных заведений в рамках одного облака)",
                        web: true,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Централизованный учет и продвинутые инструменты управления сетью (Сеть из нескольких заведений в рамках одного облака)",
                        web: true,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Глобальные программы лояльности (Одна программа лояльности на все заведения в рамках одного облака)",
                        web: true,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "Управление франшизой",
                items: [
                    {
                        name: "Консолидированная отчетность и базовые элементы управления сетью в облаке ",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                    {
                        name: "Централизованный учет и продвинутые инструменты управления сетью",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                    {
                        name: "Глобальные программы лояльности",
                        web: false,
                        start: false,
                        standard: false,
                        pro: true,
                    },
                ],
            },
            {
                title: "Дополнительные приложения",
                items: [
                    {
                        name: "Готовый сайт доставки на платформе menuquickresto.kz",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Мобильное приложение для клиентов доставки White Label",
                        web: false,
                        start: false,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "Интеграции и API",
                items: [
                    {
                        name: "1С",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "DocsInBox",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                    {
                        name: "Postilla",
                        web: true,
                        start: true,
                        standard: true,
                        pro: true,
                    },
                ],
            },
            {
                title: "Подключаемые модули",
                items: [
                    {
                        name: "Дополнительный терминал",
                        web: {
                            type: "counter",
                            price: {
                                month: 3000,
                                year: 30000,
                            },
                            max: 99,
                        },
                        start: {
                            type: "counter",
                            price: {
                                month: 8550,
                                year: 85500,
                            },
                            max: 1,
                        },
                        standard: {
                            type: "counter",
                            price: {
                                month: 11350,
                                year: 113500,
                            },
                            max: 9,
                        },
                        pro: {
                            type: "counter",
                            price: {
                                month: 14200,
                                year: 142000,
                            },
                            max: 49,
                        },
                    },
                    {
                        name: "Лояльность.Карты (электронные карты лояльности)",
                        web: {
                            type: "value",
                            price: {
                                month: "60 000 ₸ / год",
                                year: "60 000 ₸ / год",
                            }
                        },
                        start: {
                            type: "value",
                            price: {
                                month: "60 000 ₸ / год",
                                year: "60 000 ₸ / год"
                            }
                        },
                        standard: {
                            type: "value",
                            price: {
                                month: "60 000 ₸ / год",
                                year: "60 000 ₸ / год"
                            }
                        },
                        pro: {
                            type: "value",
                            price: {
                                month: "60 000 ₸ / год",
                                year: "60 000 ₸ / год"
                            }
                        },
                    },
                    {
                        name: "Электронное меню",
                        web: {
                            type: "value",
                            price: {
                                month: "71 300 ₸ / год",
                                year: "71 300 ₸ / год"
                            }
                        },
                        start: {
                            type: "value",
                            price: {
                                month: "71 300 ₸ / год",
                                year: "71 300 ₸ / год"
                            }
                        },
                        standard: {
                            type: "value",
                            price: {
                                month: "71 300 ₸ / год",
                                year: "71 300 ₸ / год"
                            }
                        },
                        pro: {
                            type: "value",
                            price: {
                                month: "71 300 ₸ / год",
                                year: "71 300 ₸ / год"
                            }
                        },
                    },
                    {
                        name: (
                            <span>
						Приложение для гостей WLApp
						<br/>
						(push-уведомления)
					</span>
                        ),
                        web: false,
                        start: false,
                        standard: {
                            type: "value",
                            price: {
                                month: "150 800 ₸ / год",
                                year: "150 800 ₸ / год"
                            }
                        },
                        pro: {
                            type: "value",
                            price: {
                                month: "150 800 ₸ / год",
                                year: "150 800 ₸ / год"
                            }
                        },
                    },
                    {
                        name: (
                            <span>
						Приложение для гостей WLApp
						<br/>
						(push-уведомления + предзаказ)
					</span>
                        ),
                        web: false,
                        start: false,
                        standard: {
                            type: "value",
                            price: {
                                month: "270 500 ₸ / год",
                                year: "270 500 ₸ / год"
                            }
                        },
                        pro: {
                            type: "value",
                            price: {
                                month: "270 500 ₸ / год",
                                year: "270 500 ₸ / год"
                            }
                        },
                    },
                    {
                        name: "Сайт для доставки с корзиной WLSite",
                        web: false,
                        start: false,
                        standard: {
                            type: "value",
                            price: {
                                month: "270 500 ₸ / год",
                                year: "270 500 ₸ / год"
                            }
                        },
                        pro: {
                            type: "value",
                            price: {
                                month: "270 500 ₸ / год",
                                year: "270 500 ₸ / год"
                            }
                        },
                    },
                ],
            }
        ]
    }

    return [
        {
            title: "Техническая поддержка 24/7",
            items: [
                {
                    name: "Мессенджеры, чат, почта",
                    web: true,
                    start: true,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Телефон",
                    web: false,
                    start: false,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Приоритетная выделенная линия",
                    web: false,
                    start: false,
                    standard: false,
                    pro: true,
                },
            ],
        },
        {
            title: "Кассовый терминал",
            items: [
                {
                    name: "Онлайн-касса, 54-ФЗ",
                    web: true,
                    start: true,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Печать тикетов на кухню",
                    web: true,
                    start: true,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Модификаторы",
                    web: true,
                    start: true,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Кухонный экран и управление очередью заказов",
                    web: true,
                    start: true,
                    standard: true,
                    pro: true,
                },
                {
                    name: "ЕГАИС (интеграция DocsInBox)",
                    web: true,
                    start: true,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Работа с маркированными товарами (Честный знак)",
                    web: true,
                    start: true,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Поддержка банковских терминалов (Оплата картой)",
                    web: true,
                    start: true,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Поддержка СБП",
                    web: false,
                    start: true,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Электронные чаевые НетМонет, CloudTips и др.",
                    web: false,
                    start: true,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Электронная очередь",
                    web: false,
                    start: true,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Экран покупателя для кассового терминала",
                    web: false,
                    start: true,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Выпуск готовых блюд (складские документы на терминале)",
                    web: false,
                    start: false,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Сервис оплаты за столиком Яндекс.Чек",
                    web: false,
                    start: false,
                    standard: false,
                    pro: true,
                },
            ],
        },
        {
            title: "Складской учет и фудкост, финансы и персонал",
            items: [
                {
                    name: "Настраиваемые виджеты оперативного учета",
                    web: true,
                    start: true,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Базовый учет - склад и техкарты",
                    web: true,
                    start: true,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Период проведения документов \"задним числом\"",
                    web: true,
                    start: true,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Период хранения документов",
                    web: true,
                    start: true,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Уведомления об опасных операциях и действиях на кассе, лимитах остатков",
                    web: true,
                    start: true,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Настройка полей и прав доступа",
                    web: true,
                    start: true,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Базовый финансовый учет",
                    web: false,
                    start: false,
                    standard: false,
                    pro: true,
                },
                {
                    name: "Продвинутый учет и аналитика, конструктор отчетов, ABC анализ",
                    web: false,
                    start: false,
                    standard: false,
                    pro: true,
                },
            ],
        },
        {
            title: "Доставка",
            items: [
                {
                    name: "Работа с заказами на самовывоз",
                    web: false,
                    start: true,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Работа с заказами на доставку на кассе",
                    web: false,
                    start: false,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Своя доставка через сервис Яндекс.Доставка",
                    web: false,
                    start: false,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Яндекс.Еда",
                    web: false,
                    start: false,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Деливери (Delivery Club)",
                    web: false,
                    start: false,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Купер",
                    web: false,
                    start: false,
                    standard: true,
                    pro: true,
                },
            ],
        },
        {
            title: "CRM",
            items: [
                {
                    name: "Идентификация гостей по номеру телефона, QR коду или через поиск",
                    web: true,
                    start: true,
                    standard: true,
                    pro: true,
                },
                {
                    name: "RFM-анализ",
                    web: false,
                    start: false,
                    standard: false,
                    pro: true,
                },
            ],
        },
        {
            title: "Управление сетью ресторанов",
            items: [
                {
                    name: "Консолидированная отчетность и базовые элементы управления сетью в облаке (Единая отчетность для разных заведений в рамках одного облака)",
                    web: true,
                    start: false,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Централизованный учет и продвинутые инструменты управления сетью (Сеть из нескольких заведений в рамках одного облака)",
                    web: true,
                    start: false,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Глобальные программы лояльности (Одна программа лояльности на все заведения в рамках одного облака)",
                    web: true,
                    start: false,
                    standard: true,
                    pro: true,
                },
            ],
        },
        {
            title: "Управление франшизой",
            items: [
                {
                    name: "Консолидированная отчетность и базовые элементы управления сетью в облаке ",
                    web: false,
                    start: false,
                    standard: false,
                    pro: true,
                },
                {
                    name: "Централизованный учет и продвинутые инструменты управления сетью",
                    web: false,
                    start: false,
                    standard: false,
                    pro: true,
                },
                {
                    name: "Глобальные программы лояльности",
                    web: false,
                    start: false,
                    standard: false,
                    pro: true,
                },
            ],
        },
        {
            title: "Дополнительные приложения",
            items: [
                {
                    name: "Готовый сайт доставки на платформе menuquickresto.ru",
                    web: false,
                    start: false,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Мобильное приложение для клиентов доставки White Label",
                    web: false,
                    start: false,
                    standard: true,
                    pro: true,
                },
            ],
        },
        {
            title: "Интеграции и API",
            items: [
                {
                    name: "1С",
                    web: true,
                    start: true,
                    standard: true,
                    pro: true,
                },
                {
                    name: "DocsInBox",
                    web: true,
                    start: true,
                    standard: true,
                    pro: true,
                },
                {
                    name: "Postilla",
                    web: true,
                    start: true,
                    standard: true,
                    pro: true,
                },
            ],
        },
        {
            title: "Подключаемые модули",
            items: [
                {
                    name: "Дополнительный терминал",
                    web: {
                        type: "counter",
                        price: {
                            month: 1500,
                            month3: 4365,
                            month6: 8460,
                            year: 15000,
                            yearOld: 15000,
                        },
                    },
                    start: {
                        type: "counter",
                        price: {
                            month: 2000,
                            month3: 5820,
                            month6: 11280,
                            year: 20000,
                            yearOld: 20000,
                        },
                        max: 1,
                    },
                    standard: {
                        type: "counter",
                        price: {
                            month: 2500,
                            month3: 7275,
                            month6: 14100,
                            year: 25000,
                            yearOld: 25000,
                        },
                        max: 9,
                    },
                    pro: {
                        type: "counter",
                        price: {
                            month: 3000,
                            month3: 8730,
                            month6: 16920,
                            year: 30000,
                            yearOld: 30000,
                        },
                        max: 49,
                    },
                },
                {
                    name: (<span>
                    Лояльность.Карты
                    <br />
                    (электронные карты лояльности)
                    </span>
                    ),
                    web: {
                        type: "value",
                        price: {
                            month: "от 1990 ₽ / мес",
                            year: "от 1990 ₽ / мес"
                        }
                    },
                    start: {
                        type: "value",
                        price: {
                            month: "от 1990 ₽ / мес",
                            year: "от 1990 ₽ / мес"
                        }
                    },
                    standard: {
                        type: "value",
                        price: {
                            month: "от 1990 ₽ / мес",
                            year: "от 1990 ₽ / мес"
                        }
                    },
                    pro: {
                        type: "value",
                        price: {
                            month: "от 1990 ₽ / мес",
                            year: "от 1990 ₽ / мес"
                        }
                    },
                },
                {
                    name: "Лояльность.360",
                    web: {
                        type: "value",
                        price: {
                            month: "от 4990 ₽ / мес",
                            year: "от 4990 ₽ / мес"
                        }
                    },
                    start: {
                        type: "value",
                        price: {
                            month: "от 4990 ₽ / мес",
                            year: "от 4990 ₽ / мес"
                        }
                    },
                    standard: {
                        type: "value",
                        price: {
                            month: "от 4990 ₽ / мес",
                            year: "от 4990 ₽ / мес"
                        }
                    },
                    pro: {
                        type: "value",
                        price: {
                            month: "от 4990 ₽ / мес",
                            year: "от 4990 ₽ / мес"
                        }
                    },
                }
            ],
        }
    ]
}

export default [
    {
        title: "Характеристики",
        items: [
            {
                id: 'characteristics-1',
                name: "Бесплатный пробный период",
                web: "14 дней",
                start: "14 дней",
                standard: "14 дней",
                pro: "14 дней",
            },
            {
                id: 'characteristics-2',
                name: "Лицензия на 1 кассовый терминал",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'characteristics-3',
                name: "Связь с кухней (чеки и экран повара)",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'characteristics-3.5',
                name: "Безлимитная номенклатура",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'characteristics-4',
                name: "Отчёты и аналитика",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'characteristics-5',
                name: "Программы лояльности",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'characteristics-6',
                name: "Приём заказов на доставку и самовывоз",
                web: false,
                start: false,
                standard: true,
                pro: true,
            },
            {
                id: 'characteristics-7',
                name: "Доставка",
                web: false,
                start: false,
                standard: true,
                pro: true,
            },
            {
                id: 'characteristics-8',
                name: "Собственные типы оплат",
                web: false,
                start: false,
                standard: false,
                pro: true,
            },
            {
                id: 'characteristics-9',
                name: "ABC-анализ, фудкост, отчёт по гостям",
                web: false,
                start: false,
                standard: false,
                pro: true,
            },
            {
                id: 'characteristics-10',
                name: "Финансовый модуль",
                web: false,
                start: false,
                standard: false,
                pro: true,
            },
            {
                id: 'characteristics-11',
                name: "Учёт рабочего времени",
                web: false,
                start: false,
                standard: false,
                pro: true,
            },
            {
                id: 'characteristics-12',
                name: "Расчёт зарплат сотрудникам",
                web: false,
                start: false,
                standard: false,
                pro: true,
            },
            {
                id: 'characteristics-13',
                name: "Техническая поддержка 24/7",
                web: (
                    <span>
						Чат, почта,
						<br/>
						мессенджеры
					</span>
                ),
                start: (
                    <span>
						Чат, почта,
						<br/>
						мессенджеры
					</span>
                ),
                standard: "Чат, почта, телефон, мессенджеры",
                pro: "Приоритет",
            },
        ],
    },
    // {
    // 	title: "Подключаемые модули",
    // 	items: [
    // 		{
    // 			id: 'modules-1',
    // 			name: "Дополнительный терминал",
    // 			web: {
    // 				type: "counter",
    // 				price: {
    // 					month: 500,
    // 					year: 5000,
    // 					yearOld: 5000,
    // 				},
    // 			},
    // 			start: {
    // 				type: "counter",
    // 				price: {
    // 					month: 1500,
    // 					year: 15000,
    // 					yearOld: 15000,
    // 				},
    // 				max: 1,
    // 			},
    // 			standard: {
    // 				type: "counter",
    // 				price: {
    // 					month: 2000,
    // 					year: 20000,
    // 					yearOld: 20000,
    // 				},
    // 				max: 9,
    // 			},
    // 			pro: {
    // 				type: "counter",
    // 				price: {
    // 					month: 2500,
    // 					year: 25000,
    // 					yearOld: 25000,
    // 				},
    // 				max: 49,
    // 			},
    // 		},
    // 		{
    // 			id: 'modules-2',
    // 			name: "Карты лояльности PassKit",
    // 			web: {
    // 				type: "checkbox",
    // 				month: false,
    // 				price: {
    // 					month: 0,
    // 					year: 9900,
    // 					yearOld: 9900,
    // 				},
    // 			},
    // 			start: {
    // 				type: "checkbox",
    // 				month: false,
    // 				price: {
    // 					month: 0,
    // 					year: 9900,
    // 					yearOld: 9900,
    // 				},
    // 			},
    // 			standard: {
    // 				type: "checkbox",
    // 				month: false,
    // 				price: {
    // 					month: 0,
    // 					year: 9900,
    // 					yearOld: 9900,
    // 				},
    // 			},
    // 			pro: {
    // 				type: "checkbox",
    // 				month: false,
    // 				price: {
    // 					month: 0,
    // 					year: 9900,
    // 					yearOld: 9900,
    // 				},
    // 			},
    // 		},
    // 		{
    // 			id: 'modules-3',
    // 			name: "Электронное меню",
    // 			web: {
    // 				type: "checkbox",
    // 				price: {
    // 					month: 1490,
    // 					year: 11880,
    // 				},
    // 			},
    // 			start: {
    // 				type: "checkbox",
    // 				price: {
    // 					month: 1490,
    // 					year: 11880,
    // 				},
    // 			},
    // 			standard: {
    // 				type: "checkbox",
    // 				price: {
    // 					month: 1490,
    // 					year: 11880,
    // 				},
    // 			},
    // 			pro: {
    // 				type: "checkbox",
    // 				price: {
    // 					month: 1490,
    // 					year: 11880,
    // 				},
    // 			},
    // 		},
    // 		{
    // 			id: 'modules-4',
    // 			name: (
    // 				<span>
    // 					Приложение для гостей WLApp
    // 					<br />
    // 					(push-уведомления)
    // 				</span>
    // 			),
    // 			web: false,
    // 			start: false,
    // 			standard: {
    // 				type: "checkbox",
    // 				month: false,
    // 				price: {
    // 					month: 0,
    // 					year: 25080,
    // 					yearOld: 25080,
    // 				},
    // 			},
    // 			pro: {
    // 				type: "checkbox",
    // 				month: false,
    // 				price: {
    // 					month: 0,
    // 					year: 25080,
    // 					yearOld: 25080,
    // 				},
    // 			},
    // 		},
    // 		{
    // 			id: 'modules-5',
    // 			name: (
    // 				<span>
    // 					Приложение для гостей WLApp
    // 					<br />
    // 					(push-уведомления + предзаказ)
    // 				</span>
    // 			),
    // 			web: false,
    // 			start: false,
    // 			standard: {
    // 				type: "checkbox",
    // 				month: false,
    // 				price: {
    // 					month: 0,
    // 					year: 45000,
    // 					yearOld: 45000,
    // 				},
    // 			},
    // 			pro: {
    // 				type: "checkbox",
    // 				month: false,
    // 				price: {
    // 					month: 0,
    // 					year: 45000,
    // 					yearOld: 45000,
    // 				},
    // 			},
    // 		},
    // 		{
    // 			id: 'modules-6',
    // 			name: "Сайт для доставки с корзиной WLSite",
    // 			web: false,
    // 			start: false,
    // 			standard: {
    // 				type: "checkbox",
    // 				month: false,
    // 				price: {
    // 					month: 0,
    // 					year: 45000,
    // 					yearOld: 59990,
    // 				},
    // 			},
    // 			pro: {
    // 				type: "checkbox",
    // 				month: false,
    // 				price: {
    // 					month: 0,
    // 					year: 45000,
    // 					yearOld: 59990,
    // 				},
    // 			},
    // 		},
    // 	],
    // },
    {
        title: "Прочие характеристики",
        items: [
            {
                id: 'other-1',
                name: "Управление складом",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'other-2',
                name: "Работа со складом с кассового терминала",
                web: false,
                start: false,
                standard: true,
                pro: true,
            },
            {
                id: 'other-3',
                name: "Приложение для руководителя",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'other-4',
                name: "Схема зала",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'other-5',
                name: "Интеграция с банковскими терминалами",
                web: false,
                start: true,
                standard: true,
                pro: true,
            },
        ],
    },
    {
        title: "Управление персоналом",
        items: [
            {
                id: 'manage-1',
                name: "Ограничение прав доступа",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'manage-2',
                name: "Сотрудники и должности",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
        ],
    },
    {
        title: "Интеграции",
        items: [
            {
                id: 'integration-1',
                name: "1С",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'integration-2',
                name: "Меркурий",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'integration-3',
                name: "ЕГАИС",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'integration-4',
                name: "DocsInBox",
                web: true,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'integration-5',
                name: "Чаевые по карте (Нетмонет, CloudTips)",
                web: false,
                start: true,
                standard: true,
                pro: true,
            },
            {
                id: 'integration-6',
                name: "Delivery Club",
                web: false,
                start: false,
                standard: true,
                pro: true,
            },
            {
                id: 'integration-7',
                name: "Яндекс Еда",
                web: false,
                start: false,
                standard: true,
                pro: true,
            },
            {
                id: 'integration-8',
                name: "Яндекс Доставка",
                web: false,
                start: false,
                standard: true,
                pro: true,
            },
        ],
    },
    {
        title: "Оплата заказов по QR-коду",
        items: [
            {
                id: 'qr-1',
                name: "Яндекс Чек",
                web: false,
                start: false,
                standard: false,
                pro: true,
            },
        ],
    },
]
